import Api from '@/api'
export default {
    data() {
        return {
            dataList: [],
            dataListParams: {
                page: 1,
                pageSize: 12,
                loading: false,
                total: 0,
                totalPage: 0
            },
            api: '',
            params: {},
            opts: {}
        }
    },
    methods: {
        _init_data(api, params, opt = {}) {
            this.$nextTick(() => {
                this.api = api
                this.dataListParams = {
                    page: 1,
                    loading: false,
                    pageSize: 12,
                    total: 0,
                    totalPage: 0
                }
                this.params = params
                this.opts = {...opt }
                this._load_data()
            })
        },
        _load_data() {
            this.dataListParams.loading = true
            this.$nextTick(() => {
                Api[this.api]({
                    page: this.dataListParams.page,
                    pageSize: this.dataListParams.pageSize,
                    ...this.params,
                }).then(res => {
                    if (res.status == 1) {
                        if (this.opts.callBack && typeof this.opts.callBack === 'function') {
                            res.data = this.opts.callBack(res.data)
                        }
                        this.dataList = res.data || []
                        this.dataListParams.total = res.count
                        this.dataListParams.totalPage = Math.ceil(res.count / this.params.pageSize ? this.params.pageSize : this.dataListParams.pageSize)
                        this.dataListParams.loading = false
                    } else {
                        this.dataListParams.loading = false
                        this.$message.error(res.msg);
                    }
                })
            })

        },
        handleCurrentChange(val) {
            this.dataListParams.page = val
            this._load_data()
        },
    }
}