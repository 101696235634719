import Api from "@/api";
import tableMinxin from "@/mixins/tableMinxin.js";

export default {
    mixins: [tableMinxin],
    data() {
        return {
            BqShow: false,
            show: true,
        }
    },
    methods: {
        onFabulous(model, type, id, url = 'study_detail') {
            if (!this.userInfo.fk_user_id) {
                this.checkLogin(
                    "login", {
                        url,
                        id: this.$route.query.id,
                    },
                    "当前未登录，是否跳转进行登录再操作"
                );
                return;
            }
            Api.Fabulous({
                model,
                type,
                fk_user_id: this.userInfo.fk_user_id,
                fk_info_id: id,
            }).then((res) => {
                if (res.status == 1) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.FabulousSuccess();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        FabulousSuccess() {},
        onSubmitComment(model, type, id, comment_id, valNmae) {
            Api.SubmitComment({
                model,
                type,
                fk_user_id: this.userInfo.fk_user_id,
                fk_info_id: id,
                fk_comment_id: comment_id,
                content: this[valNmae],
            }).then((res) => {
                if (res.status == 1) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this[valNmae] = "";
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        openBQ() {
            this.BqShow = true;
        },
        onClose() {
            this.BqShow = false;
        },
        changShow() {
            this.show = !this.show;

        },
        onLogin(url, id) {
            this.$router.push({
                name: "login",
                query: {
                    url,
                    id,
                },
            });
        },
        getOneComment(type, model, id, comment_id) {
            this._init_data(
                "Comment", {
                    type,
                    model,
                    fk_info_id: id,
                    fk_user_id: this.userInfo.fk_user_id || 0,
                    fk_comment_id: comment_id,
                }, {
                    callBack: this.formatData,
                }
            );
        },
        formatData(list) {
            if (list && list.length > 0) {
                list.forEach((item) => {
                    item.checked = false;
                });
            }

            return list;
        },

        reply(val, valName) {
            this.$emit('input', true)
            this[valName] = `回复${val.nickname}：`;
        },
        onComment(idx) {
            this.dataList.forEach((item) => {
                item.checked = false;
            });
            this.dataList[idx].checked = true;
        },
        selectEmoji(val, valNmae, valId) {
            let input = document.getElementById(valId);
            if (!this[valNmae]) {
                input.value = ''
            } else {
                input.value = this[valNmae]
            }
            let starPos = input.selectionStart;
            let endPos = input.selectionEnd;
            let content =
                input.value.substring(0, starPos) +
                val.data +
                input.value.substring(endPos);
            input.value = content;
            input.focus();
            input.selectionStart = starPos + val.data.length;
            input.selectionEnd = endPos + val.data.length;
            this[valNmae] = content;
        },
        hfRefresh() {},
    }
}