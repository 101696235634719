<template>
  <div class="info" v-html="content"></div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  line-height: 30px;
}
</style>
