var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"detail"},[_c('div',{staticClass:"video-box"},[_c('div',{staticClass:"video-content"},[_vm._m(0),_c('div',{staticClass:"content-video"},[_c('div',{staticClass:"video-play"},[_c('div',{staticClass:"video"},[(_vm.ChapterList[0])?[(
                  _vm.ChapterList[_vm.chapterCurrent] &&
                  _vm.ChapterList[_vm.chapterCurrent].fileType == 'img'
                )?_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.ChapterList[_vm.chapterCurrent].file_path,"alt":""}})]):(
                  _vm.ChapterList[_vm.chapterCurrent] &&
                  _vm.ChapterList[_vm.chapterCurrent].fileType == 'video'
                )?_c('div',{staticStyle:{"width":"100%","height":"100%"}},[(
                    _vm.ChapterList[_vm.chapterCurrent] &&
                    _vm.ChapterList[_vm.chapterCurrent].file_path
                  )?_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.ChapterList[_vm.chapterCurrent].file_path,"autoplay":"","onplaying":_vm.onPlay(),"controls":""}}):_vm._e()]):_c('div',{staticClass:"play-show"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"width":"1200px","height":"673px","src":'https://eduapi.114kaili.com/Common/DocView?file_path=' +
                    _vm.ChapterList[_vm.chapterCurrent].file_path}})])]:_vm._e(),_c('div',{class:['switch-box', _vm.showVideo ? 'active' : '']},[_c('div',{staticClass:"switch"},[_c('img',{staticClass:"switch-icon-open",attrs:{"src":require("@/assets/teacherTraining/ic_7.png"),"alt":""},on:{"click":_vm.showList}}),_c('div',{staticClass:"switch-list"},[_c('div',{staticClass:"switch-list-title"},[_vm._v("章节列表")]),_c('div',{staticClass:"chapter-list"},_vm._l((_vm.ChapterList),function(item,index){return _c('div',{key:index,class:[
                        'chapter-item',
                        index == _vm.chapterCurrent ? 'active' : '' ],on:{"click":function($event){return _vm.changchapter(index)}}},[_c('div',{staticClass:"item-left"},[(item.fileType == 'video')?[(_vm.playing && index == _vm.chapterCurrent)?_c('img',{staticClass:"chapter-icon",attrs:{"src":"https://eduapi.114kaili.com/Content/images/player_ico.gif","alt":""}}):_c('img',{staticClass:"chapter-icon",attrs:{"src":require("@/assets/teacherTraining/ic_8.png"),"alt":""}})]:_c('img',{staticClass:"chapter-icon",attrs:{"src":require("@/assets/resourceSharing/ic_5.png"),"alt":""}}),_c('span',{staticClass:"line-1",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(item.name))])],2),_c('div',{staticClass:"item-right",on:{"click":function($event){$event.stopPropagation();return _vm.onDownload(item)}}},[_vm._v(" 下载 ")])])}),0)])])])],2),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showVideo),expression:"!showVideo"}],staticClass:"switch-icon",attrs:{"src":require("@/assets/teacherTraining/ic_6.png"),"alt":""},on:{"click":_vm.showList}})]),_c('div',{staticClass:"operation"},[_c('div',{staticClass:"operation-left"},[_c('span',{staticClass:"viewNum"},[_vm._v("阅读量："+_vm._s(_vm.dataForm.read_num))]),_c('span',[_vm._v("评论数："+_vm._s(_vm.dataForm.comment_count))])]),_c('div',{staticClass:"operation-right"},[_c('div',{class:[
                'fabulous',
                _vm.dataForm.fabulous_status == 1 ? 'active-fabulous' : '' ],on:{"click":function($event){return _vm.onFabulous(1, 2, _vm.dataForm.fk_resource_id, 'resourse_detail')}}},[(_vm.dataForm.fabulous_status == 1)?_c('img',{staticClass:"fabulous-icon",attrs:{"src":require("@/assets/teacherTraining/ic_4_fill.png"),"alt":""}}):_c('img',{staticClass:"fabulous-icon",attrs:{"src":require("@/assets/teacherTraining/ic_4.png"),"alt":""}}),_c('span',[_vm._v("点赞（"+_vm._s(_vm.dataForm.fabulous_count)+"）")])]),_c('div',{class:[
                'collection',
                _vm.dataForm.collect_status == 1 ? 'active-fabulous' : '' ],on:{"click":function($event){return _vm.onFabulous(2, 2, _vm.dataForm.fk_resource_id, 'resourse_detail')}}},[(_vm.dataForm.collect_status == 1)?_c('img',{staticClass:"collection-icon",attrs:{"src":require("@/assets/teacherTraining/ic_5_fill.png"),"alt":""}}):_c('img',{staticClass:"collection-icon",attrs:{"src":require("@/assets/teacherTraining/ic_5.png"),"alt":""}}),_c('span',[_vm._v("收藏")])])])])]),_c('div',{staticClass:"detail-info"},[_c('div',{staticClass:"info-title line-2"},[_vm._v(" "+_vm._s(_vm.dataForm.name)+" ")]),_c('div',{staticClass:"info-introduction line-2"},[_vm._v(" 课程简介："+_vm._s(_vm.dataForm.remark)+" ")])])])]),_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"box-center"},[_c('div',{staticClass:"center-title-box"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,class:['leaving', index == _vm.current ? 'active' : ''],on:{"click":function($event){return _vm.change(index)}}},[_c('span',[_vm._v(_vm._s(item.name))]),(item.num > 0)?_c('span',{staticClass:"leavingNum"},[_vm._v(_vm._s(item.num))]):_vm._e()])}),0),_c('div',{staticClass:"center-content"},[_c('comment',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 0),expression:"current == 0"}],attrs:{"dataForm":_vm.dataForm}}),_c('base-info',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 1),expression:"current == 1"}],attrs:{"content":_vm.dataForm.content}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-title"},[_c('span',[_vm._v("当前位置：")]),_c('div',{staticClass:"title-list"},[_c('div',{staticClass:"title-item"},[_vm._v("首页")]),_c('img',{staticClass:"title-icon",attrs:{"src":require("@/assets/teacherTraining/ic_13.png")}}),_c('div',{staticClass:"title-item"},[_vm._v("资源分享")]),_c('img',{staticClass:"title-icon",attrs:{"src":require("@/assets/teacherTraining/ic_13.png")}}),_c('div',{staticClass:"title-item"},[_vm._v("教研课程")])])])}]

export { render, staticRenderFns }