<template>
  <div class="reply">
    <div class="bgc" v-show="BqShow" @click="onClose"></div>
    <div class="reply-title" @click="changShow" v-if="dataList && dataList[0]">
      <span>查看更多评论({{ obj.comment_count }})</span>
      <img
        :class="['title-icon', show ? 'up' : '']"
        src="@/assets/teacherTraining/ic_10.png"
        alt=""
      />
    </div>
    <div
      class="reply-list"
      :style="{ height: show ? (endHeight ? endHeight + 'px' : '') : '0' }"
      ref="replyList"
    >
      <div class="reply-item" v-for="(item, index) in dataList" :key="index">
        <div class="item-left">
          <img class="left-logo" :src="item.headimgurl" alt="" />
        </div>
        <div class="item-right">
          <div class="user-box">
            <span class="user-name">{{ item.nickname }}</span>
            <span class="user-time">{{ item.date_creation }}</span>
          </div>
          <div class="right-comment">
            {{ item.content }}
          </div>
          <div class="right-operation">
            <div class="operation-reply" @click="reply(item, 'contentVal')">
              <img
                class="operation-reply-img"
                src="@/assets/teacherTraining/ic_9.png"
                alt=""
              />
              <span>回复</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="value">
      <div class="right-input-box" v-if="userInfo.fk_user_id > 0">
        <el-input
          id="hfinput"
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="contentVal"
        >
        </el-input>
        <div class="bq" v-show="BqShow">
          <VEmojiPicker
            @select="selectEmoji($event, 'contentVal', 'hfinput')"
          />
        </div>
        <div class="input-btn-box">
          <img
            class="btn-icon"
            src="@/assets/teacherTraining/ic_11.png"
            alt=""
            @click="openBQ"
          />
          <div
            class="input-btn"
            @click="
              onSubmitComment(2, 2, comment_id, obj.fk_comment_id, 'contentVal')
            "
          >
            发布
          </div>
        </div>
      </div>
      <div class="comment-input-box" v-else>
        <div
          class="login-btn"
          @click="onLogin('study_detail', $route.query.id)"
        >
          登录
        </div>
        <span class="tip">登陆后才可留言</span>
      </div>
    </template>
  </div>
</template>

<script>
import detail from "@/mixins/detail.js";
import Api from "@/api";
export default {
  props: {
    obj: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
    comment_id: {
      type: Number,
      default: 0,
    },
  },
  mixins: [detail],
  data() {
    return {
      contentVal: "",
      dataList: [],
      endHeight: "",
    };
  },
  mounted() {
    this.initComment();
  },
  methods: {
    initComment() {
      Api.Comment({
        model: 2,
        type: 2,
        fk_info_id: this.comment_id,
        fk_comment_id: this.obj.fk_comment_id,
        fk_user_id: 0,
        page: 1,
        pageSize: 100,
      }).then((res) => {
        if (res.status == 1) {
          this.dataList = res.data;
          this.$nextTick(() => {
            this.endHeight = this.$refs.replyList.offsetHeight;
            this.show = false;
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  resize: none;
}
::v-deep .el-textarea__inner {
  border: none;
  padding: 0;
}

.bgc {
  width: 1920px;
  height: 1500px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.comment-input-box {
  flex: 1;
  height: 115px;
  padding: 18px;
  display: flex;
  font-size: 14px;

  font-weight: 400;
  color: #666666;
  border: 1px solid #f3f3f4;
  border-radius: 4px;
  .tip {
    padding-top: 7px;
  }
  .login-btn {
    width: 70px;
    height: 30px;
    border-radius: 15px;
    background: #0086ff;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    color: #ffffff;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.user-box {
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
  .user-name {
    font-size: 16px;

    font-weight: 400;
    color: #999999;
    padding-right: 12px;
  }
  .user-time {
    font-size: 12px;

    font-weight: 400;
    color: #999999;
  }
}
.reply {
  .reply-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    color: #999999;
    padding-bottom: 27px;
    .title-icon {
      width: 16px;
      height: 10px;
      margin-left: 10px;
      transition: all 0.6s ease;
      &.up {
        transform: rotate(-180deg);
        transition: all 0.6s ease;
      }
    }
  }
  .reply-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: height 0.4s ease;
    overflow: hidden;
    .reply-item {
      width: 100%;
      display: flex;
      padding-bottom: 20px;
      .item-left {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px;

        .left-logo {
          width: 100%;
          height: 100%;
        }
      }
      .item-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        .right-comment {
          width: 100%;
        }
        .right-operation {
          width: 100%;
          display: flex;
          align-items: center;
          padding-top: 13px;
          .operation-time {
            font-size: 12px;

            font-weight: 400;
            color: #999999;
            padding-right: 20px;
          }
          .operation-reply {
            display: flex;
            align-items: center;
            font-size: 16px;
            cursor: pointer;
            font-weight: 400;
            color: #999999;
            .operation-reply-img {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .right-input-box {
    width: calc(100% - 42px);
    height: 115px;
    margin-left: 42px;
    border: 1px solid #f3f3f4;
    border-radius: 4px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
    .bq {
      position: absolute;
      left: -16px;
      bottom: -425px;
      z-index: 100;
    }
    .input-btn-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn-icon {
        width: 19px;
        height: 19px;
        cursor: pointer;
      }
      .input-btn {
        width: 70px;
        height: 30px;
        background: #0086ff;
        border-radius: 15px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
